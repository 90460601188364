.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;

    min-height: 50vh;
}

.topContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* background-color: #f3f3f3; */
    
    padding: 20px 0;
    
}

/* .products > p {
    margin: 20px 0;
    font-size: 80px;
    font-weight: bold;

    background-color: black;
} */

.flexItem {
    flex: 0 0 75%;
    max-width: 75%;
}

@media(min-width: 768px) {
    .flexItem {
        flex: 0 0 50%;
        max-width: 50%;
    } 
}

@media(min-width: 992px) {
    .flexItem {
        flex: 0 0 30%;
        max-width: 30%;
    } 
}