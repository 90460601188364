.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.lowerContainer {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.header {
    font-size: 30px;
    font-family: fantasy;
}

.detail {
    width: 300px;
}

@media(min-width: 768px) {
    .lowerContainer {
        flex-direction: row;
        align-items: center;
    }
}