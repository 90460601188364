.container {
    width: 373px;
    height: 373px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: .25rem;

}

.container:hover {
    box-shadow: 2px 2px 2px 2px #888888;
}


.lowerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 373px;
    height: 48px;

    padding: .75rem 1.25rem;
}

.container:hover .imgCard {
    transform: scale(1.2);
}

.container:hover .cartBtn {
    transition: all 1s linear 0s;
    transform: translate(0, 0);
}

.imgContainer {
    position: relative;
    overflow: hidden;
}

.imgCard {
    transition: all 1s linear 0s;

    width: 277px;
    height: 277px;

    /* position: relative; */
    /* overflow: hidden; */

    margin-top: 22px;
}

.cartBtn {
    position: absolute;
    bottom: -10px;
    right: -10px;
    padding: 0.2rem 0.4rem;

    transition: all 1s linear 0s;
    transform: translate(100%, 100%);
}



@media(min-width: 768px) {
    .container {
        width: 328px;
        height: 328px;
    }

    .imgCard {
        width: 232px;
        height: 232px;
    }

    .lowerContainer {
        width: 328px;
        height: 48px;
    }
}

@media(min-width: 992px) {
    .container {
        width: 253px;
        height: 258px;
    }

    .imgCard {
        width: 157px;
        height: 157px;
    }

    .lowerContainer {
        width: 253px;
        height: 48px;
    }
}