@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #f3f3f3;
}

/* img {
  width: 100%;
  height: 100%;
} */
