.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* margin: 0 auto; */
    padding: 15px;
    width: 400px;
    gap: 5px;
}

.imgContainer > img {
    width: 100px;
    height: 100px;
}

.buttonContainer {
    display: flex;
    gap: 10px;
}

.delete:hover {
    cursor: pointer;
}

.cartitems {
    display: flex;
    gap: 5px;
    /* flex-direction: column; */
}

.title {
    font-weight: bold;
}

.value {
    font-style: italic;
}

@media(min-width: 600px) {
    .container {
        margin: 0 auto;
        width: 500px;
        gap: 30px;
        flex-direction: row;
    }

    .cartitems {
        gap: 5px;
        flex-direction: column;
    }
}

@media(min-width: 768px) {
    .container {
        margin: 0 auto;
        width: 700px;
        gap: 30px;
        /* flex-direction: row; */
    }
}

@media(min-width: 992px) {
    .flexItem {
        flex: 0 0 30%;
        max-width: 30%;
    } 
}