.itemContainer {
    display: flex;
    /* gap: 80px; */
    justify-content: space-between;
}


@media(min-width: 768px) {
    .itemContainer {
        width: 50%;
        
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

